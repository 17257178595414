import { Component, OnInit, Input } from '@angular/core';
import { ITopic } from "../../interfaces/Contents";

@Component({
  selector: 'topics-list',
  templateUrl: './topics-list.component.html',
  styleUrls: ['./topics-list.component.scss'],
})

export class TopicsListComponent implements OnInit {

	@Input() topics:ITopic[];

	constructor() { }

	ngOnInit() {}

}
