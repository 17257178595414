import { Injectable } from '@angular/core';
import { IUser, IUserPlan } from '../interfaces/User';
import { NavController } from '@ionic/angular';

@Injectable({
 	providedIn: 'root'
})
export class UserService {

	currentUser:IUser;

	constructor(
		public navCtrl: NavController,
	) {
		this.load();	
	}

	load(){
		if(localStorage.getItem('SimSaveUserSession') !== null){
			this.currentUser = JSON.parse(localStorage.getItem('SimSaveUserSession'));
		}
	}

	save(){
		localStorage.setItem("SimSaveUserSession", JSON.stringify(this.currentUser));
	}

	setToken(newToken:string){
		this.currentUser = {} as IUser;
		this.currentUser.token = newToken;
		this.save();
	}

	set(data:any){
		let token = this.currentUser.token;
		this.currentUser = data as IUser;
		this.currentUser.token = token;
		this.save();
	}

	getPlan(product_slug:string):IUserPlan {
		if(!this.checkLogin()) return undefined;
		return this.currentUser.plans.find((v:IUserPlan)=>v.product.slug==product_slug);
	}

	hasPlan = ():boolean => this.currentUser.plans.length>0;
	hasPlanBySlug = (slug:string):boolean => this.currentUser.plans.some(v=>v.product.slug==slug) ? true : false;

	getPlans = ():IUserPlan[] => this.currentUser.plans;

	getFirstPlan = ():IUserPlan => this.hasPlan() ? this.currentUser.plans[0] : undefined;

	checkLogin = ():boolean => (this.currentUser && this.currentUser.token) ? true : false;

	checkSubscribed = (product_slug:string):boolean => this.checkLogin() && (this.get().is_admin || (this.getPlan(product_slug) && this.getPlan(product_slug).subscriber))

	compareId = (id:number):boolean => this.checkLogin() && this.currentUser.id==id;

	get = ():IUser => this.currentUser;
	
	getToken = ():string => this.currentUser.token;

	setSelectedProduct = (product_slug:string) => localStorage.setItem("SimSaveUserProduct", product_slug);
	getSelectedProduct = () => localStorage.getItem("SimSaveUserProduct");

	checkUserHasProduct (){

		if(!this.currentUser.is_admin && !this.hasPlanBySlug(this.getSelectedProduct())){
			this.navCtrl.navigateRoot("/checkout/plans");
		}

	}

	isAdmin = () => this.currentUser.is_admin;

	logout() {
		localStorage.removeItem("SimSaveUserSession");
		localStorage.removeItem("SimSaveUserProduct");
		localStorage.removeItem("darkMode");
	}

}
