import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LoadingController } from '@ionic/angular';
import { ApiRequestService } from 'src/app/services/api-request.service';

@Component({
  selector: 'app-forum-form-dialog',
  templateUrl: './forum-form-dialog.component.html',
  styleUrls: ['./forum-form-dialog.component.scss'],
})
export class ForumFormDialogComponent implements OnInit {

	constructor(
		public dialogRef: MatDialogRef<ForumFormDialogComponent>,
		private api:ApiRequestService,
		public loadingController: LoadingController,
	) { }

	newThread = new FormGroup({
			title: new FormControl("", [Validators.required] ),
			text: new FormControl("", [Validators.required] ),
			topics: new FormControl([]),
			convert_p: new FormControl(true)
		});
	ionLoading:any;
	sendingThread:boolean = false;

	ngOnInit() {}

	validateField = (field:string):boolean => this.newThread.get(field).touched && !this.newThread.get(field).valid;
  	async sendThread() {
		
		this.ionLoading = await this.loadingController.create({
			message: 'Aguarde...'
		});
		await this.ionLoading.present();
	
		if (this.newThread.valid) {
			this.sendingThread = true;
			this.api.post("forum_thread", this.newThread.value, true).subscribe((res: any) => {
				this.sendingThread = false;
	
				this.newThread.get("title").setValue("");
				this.newThread.get("text").setValue("");
	
				this.hideLoading();
				window.location.href = '/forum'; 
			}, (error) => {
				this.hideLoading(); 
			});
		} else {
			this.sendingThread = false;
			await this.hideLoading();
		}
	}

	async hideLoading() {
		this.ionLoading.dismiss();
	}

	close(){
		this.dialogRef.close(true);
	}
}
