import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'Sec2Min'
})
export class Sec2MinPipe implements PipeTransform {

	transform(value: any, args?: any): any {

		let s = value;
		let date = new Date(null);
		date.setSeconds(s);
		let result = date.toISOString().substr(14, 5);

		return result;
	}

}
