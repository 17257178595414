import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";

import { IconHeaderComponent } from './icon-header/icon-header.component';
import { InstructorCardComponent } from './instructor-card/instructor-card.component';
import { ForumThreadCardComponent } from './forum-thread-card/forum-thread-card.component';
import { ForumThreadContentCardComponent } from './forum-thread-content-card/forum-thread-content-card.component';
import { JourneyCardComponent } from './journey-card/journey-card.component';
import { TagComponent } from './tag/tag.component';
import { TopicCardComponent } from './topic-card/topic-card.component';
import { VideoclassCardComponent } from './videoclass-card/videoclass-card.component';
import { VideoclassItemComponent } from './videoclass-item/videoclass-item.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { CardButtonComponent } from './card-button/card-button.component';
import { AccordionItemComponent } from './accordion-item/accordion-item.component';
import { UserThumbComponent } from './user-thumb/user-thumb.component';
import { TopicsListComponent } from './topics-list/topics-list.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { JourneyItensMenuComponent } from './journey-itens-menu/journey-itens-menu.component';
import { CheckoutTimelineComponent } from './checkout-timeline/checkout-timeline.component';
import { PopoverComponent } from './popover/popover.component';
import { ContentPreviewModalComponent } from './content-preview-modal/content-preview-modal.component';

// PIPES
import { CheckImageUrlPipe } from '../pipes/check-image-url.pipe';
import { StripTagsPipe } from '../pipes/strip-tags.pipe';
import { Nl2brPipe } from '../pipes/nl2br.pipe';
import { Sec2MinPipe } from '../pipes/sec-2-min-sec.pipe';

//EXTERNAL
import {AutosizeModule} from 'ngx-autosize';
import { ForumFormDialogComponent } from './forum-form-dialog/forum-form-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    AutosizeModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    IconHeaderComponent,
    InstructorCardComponent,
    ForumThreadCardComponent,
    ForumThreadContentCardComponent,
    JourneyCardComponent,
    TagComponent,
    TopicCardComponent,
    VideoclassCardComponent,
    VideoclassItemComponent,
    ProgressBarComponent,
    CardButtonComponent,
    AccordionItemComponent,
    UserThumbComponent,
    TopicsListComponent,
    EmptyStateComponent,
    JourneyItensMenuComponent,
    CheckoutTimelineComponent,
    PopoverComponent,
    ContentPreviewModalComponent,
    ForumFormDialogComponent,

    // PIPES
    CheckImageUrlPipe,
    StripTagsPipe,
    Nl2brPipe,
    Sec2MinPipe
    
  ],
  exports: [
    IconHeaderComponent,
    InstructorCardComponent,
    ForumThreadCardComponent,
    ForumThreadContentCardComponent,
    JourneyCardComponent,
    TagComponent,
    TopicCardComponent,
    VideoclassCardComponent,
    VideoclassItemComponent,
    ProgressBarComponent,
    CardButtonComponent,
    AccordionItemComponent,
    UserThumbComponent,
    TopicsListComponent,
    EmptyStateComponent,
    JourneyItensMenuComponent,
    CheckoutTimelineComponent,
    PopoverComponent,
    ContentPreviewModalComponent,
    ForumFormDialogComponent,

    // PIPES
    CheckImageUrlPipe,
    StripTagsPipe,
    Nl2brPipe,
    Sec2MinPipe
    
  ]
})

export class SharedComponentsModule { }