import { Pipe, PipeTransform } from '@angular/core';
import { isDevMode } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
	name: 'checkImageUrl'
})
export class CheckImageUrlPipe implements PipeTransform {

	transform(value:string, type?: any, size?: any): any {


		if(value=="" || value==null) {

			switch (type) {
				case "user":
					return "assets/placeholder/user.svg";
					break;
				case "topic":
					return "assets/placeholder/topic.svg";
					break;
				default:
					return "assets/placeholder/image.svg";
					break;
			}
		} else if(type=="local"){
			return environment.apiUrl+"v1/arquivos/"+value;
		} else {

			if(size){
				value = value.replace("{size}", size);
			} else {
				value = value.replace("{size}/", "");
			}

			return value;

		}
	}

}
