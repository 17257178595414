import { CurrencyPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadingController } from '@ionic/angular';
import { IUserPlan } from 'src/app/interfaces/User';
import { ApiRequestService } from 'src/app/services/api-request.service';
import { Config } from 'src/app/utils/Config';

@Component({
  selector: 'app-payment-info-dialog',
  templateUrl: './payment-info-dialog.component.html',
  styleUrls: ['./payment-info-dialog.component.scss']
})
export class PaymentInfoDialogComponent implements OnInit {
	config = Config;
	processingPayment:boolean = false;
	installment_info = null;
	showErrorMessage:boolean = false;
	errorMessage:string = "";
	reloadPage = false;
	constructor(
		public dialogRef: MatDialogRef<PaymentInfoDialogComponent>,
		private api:ApiRequestService,
		public loadingController: LoadingController,
		@Inject(MAT_DIALOG_DATA) public data: any,

	) { }

	ionLoading:any;

	ngOnInit() {
		this.installment_info = this.data.plan_installment;
	}

	async generatePayment(payment_method:string, plan:IUserPlan, plan_installment:any) {

		this.processingPayment = true;
		this.reloadPage = true;

		let data: { plan: IUserPlan; plan_installment: any; payment_method: string } = { 
			plan: plan,
			plan_installment: plan_installment,
			payment_method: payment_method
		};
		
		this.api.post("user/plans/recurrence-payment", data).subscribe((response:any)=>{
			console.log("response ", response);

			if(response){
				this.installment_info = null;
				this.installment_info = response;
				this.processingPayment = false;
			}
		},
		(error: any) => {
			this.errorMessage = "Erro ao processar pagamento: ";
			
			if (error.error && error.error.error_message) {
				this.errorMessage += error.error.error_message + ".";
			}
			this.showErrorMessage = true;
			this.processingPayment = false;
		});

	}

	copyText(text) {
		window.navigator["clipboard"].writeText(text);
	}

	async hideLoading() {
		this.ionLoading.dismiss();
	}

	close(){
		this.dialogRef.close(this.reloadPage);
	}
}
