import { Component, OnInit, Input } from '@angular/core';
import { ITopic } from "../../interfaces/Contents";
import { ApiRequestService } from "../../services/api-request.service";

@Component({
  selector: 'topic-card',
  templateUrl: './topic-card.component.html',
  styleUrls: ['./topic-card.component.scss'],
})
export class TopicCardComponent implements OnInit {

	@Input() data:ITopic;
	getLink = ()=> "/topic/"+this.data.id;
	loading:boolean = false;

	constructor(
		private api:ApiRequestService
	) { }

	ngOnInit() {}

	postTopic(type:string) {
		this.loading = true;
		this.api.post("topic/"+this.data.id+"/"+type).subscribe((res:any)=>{
			this.data.selected = res.selected;
			this.data.users_count = res.users_count;
			this.loading = false;
		})
	}

	follow() {
		this.postTopic('follow');
	}

	unfollow() {
		this.postTopic('unfollow');
	}
}
