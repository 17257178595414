import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

	private popoverController: PopoverController;

	@Input() public dismiss = () => {}
	@Input() public menu = () => {}

	constructor() { }

	ngOnInit() {}

}
