import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent implements OnInit {

	@Input() ico:string;
	@Input() title:string;
	@Input() text:string;

	constructor() { }

	ngOnInit() {}

}
