import { Component, OnInit, Input } from '@angular/core';
import { ApiRequestService } from '../../services/api-request.service';
import { NavController } from '@ionic/angular';
import { UserService } from '../../services/user.service';
import { IForum } from '../../interfaces/Contents';

import { PopoverController } from '@ionic/angular';
import { PopoverComponent } from '../../components/popover/popover.component';
import { ForumDetailPage } from '../../pages/forum/forum-detail/forum-detail.page';


@Component({
  selector: 'forum-thread-content-card',
  templateUrl: './forum-thread-content-card.component.html',
  styleUrls: ['./forum-thread-content-card.component.scss'],
})
export class ForumThreadContentCardComponent implements OnInit {

	@Input() data:IForum;
	@Input() type:string = "thread";
	@Input() parent:ForumDetailPage;


	voting:boolean = false;

	constructor(
		public navCtrl: NavController,
		private api:ApiRequestService,
		public currentUser:UserService,
		public popoverController: PopoverController
	) { }

	ngOnInit() {}

	isVote = (v:number):boolean => this.data.my_vote.length>0 && this.data.my_vote[0].vote==v;
	threadId = ():number => this.type=="thread" ? this.data.id : this.data.thread_id;
	replyId = ():number => this.type=="thread" ? undefined : this.data.id;
	
	vote(v:number) {

		let d = {
			vote: v,
			reply_id: this.replyId()
		};

		this.voting = true;
		this.api.post("forum_thread/"+this.threadId()+"/vote", d).subscribe((res:IForum)=>{
			this.voting = false;

			if(this.type=="thread"){
				this.data = res;
			} else {
				this.data = res.replies.find(d=>d.id==this.data.id);
			}
		})
	}

	delete() {
		let url = this.type=="thread" ? "forum_thread/"+this.threadId() : "forum_thread/"+this.threadId()+"/reply/"+this.replyId();
		this.api.delete(url).subscribe(res=>{

			if(this.type=="thread"){
				this.navCtrl.navigateBack("/forum");
			} else {
				this.parent.loadJourney();
			}
		});
	}

	async presentPopover(ev: any) {
		const popover = await this.popoverController.create({
			component: PopoverComponent,
			event: ev,
			componentProps: {
				menu: [
					{
						label: "Excluir",
						click: ()=>{
							this.delete();
							popover.dismiss();
						}
					}
				],
				dismiss: () => {
					popover.dismiss();
				}
			}
		});
		return await popover.present();
	}

}
