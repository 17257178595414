import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedComponentsModule } from './components/shared-components.module'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);

import { CurrencyPipe } from '@angular/common'

import { NgxMaskModule, IConfig } from 'ngx-mask'
import {MatDialogModule} from '@angular/material/dialog';
import { DialogsModule } from './dialogs/payment-info/dialogs.module';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
  	BrowserModule, 
  	IonicModule.forRoot({
      scrollPadding: false
    }), 
    NgxMaskModule.forRoot(),
  	AppRoutingModule,
  	SharedComponentsModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    CommonModule,
    DialogsModule
  ],
  providers: [
    CurrencyPipe,
    StatusBar,
    InAppPurchase2,
    SplashScreen,
    DatePipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {provide: LOCALE_ID, useValue: "pt-BR" },
    {provide:  DEFAULT_CURRENCY_CODE, useValue: 'BRL'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
