import { Component, OnInit, Input } from '@angular/core';
import { IJourneyTitles } from "../../interfaces/Contents";

@Component({
	selector: 'accordion-item',
	templateUrl: './accordion-item.component.html',
	styleUrls: ['./accordion-item.component.scss'],
})
export class AccordionItemComponent implements OnInit {

	@Input() data:IJourneyTitles;
	@Input() type:string = "journey-title";
	@Input() queryParams:any;
	@Input() closed:boolean;

	title = ()=> this.data.name;


	constructor() { }

	ngOnInit() {}

}
