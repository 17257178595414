import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentInfoDialogComponent } from './payment-info-dialog.component';

@NgModule({
  declarations: [
    PaymentInfoDialogComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    PaymentInfoDialogComponent,
  ],
})
export class DialogsModule {}
