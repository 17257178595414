import { Component, OnInit, Input } from '@angular/core';
import { IJourney } from "../../interfaces/Contents";

@Component({
  selector: 'journey-card',
  templateUrl: './journey-card.component.html',
  styleUrls: ['./journey-card.component.scss'],
})
export class JourneyCardComponent implements OnInit {

	@Input() data:IJourney;

	constructor() { }

	ngOnInit() {}

	getLink = ()=> "/disciplina/"+this.data.slug;

}
