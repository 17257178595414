import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import {map, tap} from 'rxjs/operators';
//import * as AWS from 'aws-sdk';
//import 'aws-sdk/lib/node_loader'; // Hack needed before the first import
//import { CognitoIdentityCredentials, Config } from 'aws-sdk/lib/core'; // or any other `aws-sdk` export
//import S3 from 'aws-sdk/clients/s3';

import { EventEmitterService } from './event-emitter.service';
import { UserService } from './user.service';


//var AWS = require('aws-sdk/global');

@Injectable({
 	providedIn: 'root'
})
export class ApiRequestService {


	constructor(
		private currentUser:UserService,
		private http: HttpClient,
		private router: Router,
		private route: ActivatedRoute,
	) { }
	
	GetHeaders():object {

		let h:HttpHeaders;

		if( this.currentUser.checkLogin() ){

			h = new HttpHeaders({
                'Authorization': 'Bearer '+ this.currentUser.getToken(),
				'Accept': 'application/json',
				'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
			})

		} else {

			h = new HttpHeaders({
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			})

		}

		return { headers: h }
		
	}

	getProduct = () => this.currentUser.getSelectedProduct();


	post(url, data?,product?){
		
		let productUrl = (!product || product == undefined) ? "":this.getProduct()+"/";

		return new Observable((observer) => {
			let post = this.http.post(
				environment.apiUrl+"v1/"+productUrl+url,
				JSON.stringify(data),
				this.GetHeaders()
			).subscribe(success=>{
				observer.next(success);
			}, error=>{
				if(error.status==401) EventEmitterService.get("loginError").emit(error.error);
				observer.error(error);
			});
		});
	}

	get(url:string, product?, data?){

		let productUrl = (!product || product == undefined) ? "":this.getProduct()+"/";
		if(data) {
			url = url+"?"+new HttpParams({ fromObject: data }).toString();
		}

		return new Observable((observer) => {
			this.http.get(
			environment.apiUrl+"v1/"+productUrl+url,
				this.GetHeaders()
			).subscribe(success=>{
				observer.next(success);
			}, error=>{
				if(error.status==401) EventEmitterService.get("loginError").emit(error.error);
				observer.error(error);
			});
		});
	}

	delete(url,product?){
		let productUrl = (!product || product == undefined) ? "":this.getProduct()+"/";
		return this.http.delete(
			environment.apiUrl+"v1/"+productUrl+url,
			this.GetHeaders()
		)
	}

	getCep(zipCode:string){

		zipCode = zipCode.replace(".", "");
		zipCode = zipCode.replace("-", "");

		return this.http.get(
			"https://viacep.com.br/ws/"+zipCode+"/json/"
		)
	}

	pdf(url,data?){
		return new Observable((observer) => {
		  let headers = new HttpHeaders();
		  headers.append("Content-Type", "application/json");
			let post = this.http.post(
				environment.apiUrl+"v1/"+url,
				JSON.stringify(data),
				{
					headers: headers,
					responseType: 'blob'
				}
			).subscribe(success=>{
				observer.next(success);
			}, error=>{
				if(error.status==401) EventEmitterService.get("loginError").emit(error.error);
				observer.error(error);
			});
		});
	}

	pdfAuthenticated(url,data?){
		return new Observable((observer) => {
			let headers = new HttpHeaders();
			headers.append("Content-Type", "application/json");
			headers = headers.append('Authorization', 'Bearer ' + this.currentUser.getToken());
			let post = this.http.post(
				environment.apiUrl+"v1/"+url,
				JSON.stringify(data),
				{
					headers: headers,
					responseType: 'blob'
				}
			).subscribe(success=>{
				observer.next(success);
			}, error=>{
				if(error.status==401) EventEmitterService.get("loginError").emit(error.error);
				observer.error(error);
			});
		});
	}


	/*

	Login(data){

		return this.http.post(
			environment.apiUrl+"login",
			JSON.stringify(data),
			this.GetHeaders()
		)
	}


	GetProduct():string {
		 if(this.router.routerState.root.children[0] && this.router.routerState.root.children[0].children[0]) {
		 	if(this.router.routerState.root.children[0].children[0].snapshot.params.product){
            	return this.router.routerState.root.children[0].children[0].snapshot.params.product;
		 	} else {
		 		return "all";
		 	}
        } else {
            return "";
        }
	}

	LinkContent(data:any):string{
		return "/"+this.GetProduct()+"/content/"+data.slug+"/0";
	}

	

	Delete(url,product?){
		let productUrl = (!product || product == undefined) ? "":this.GetProduct()+"/";
		return this.http.delete(
			environment.apiUrl+"v1/"+productUrl+url,
			this.GetHeaders()
		)
	}

	Upload(file){

		let h = new HttpHeaders({
            'Authorization': 'Bearer '+this.usuario.data.token,
			'Accept': 'application/json'
		})

		let uploadData = new FormData();
  		uploadData.append('file', file);


		return this.http.post(
			environment.apiUrl+"v1/upload", 
			uploadData,
			{ headers: h }			
		)
	}

	

	GetCep(zipCode:string){

		zipCode = zipCode.replace(".", "");
		zipCode = zipCode.replace("-", "");

		return this.http.get(
			"https://viacep.com.br/ws/"+zipCode+"/json/"
		)
	}

	UploadS3(file:File, folder?: string) {

		if(!folder) {
			folder = "";
		}

		folder = "public/"+folder;


		if(!environment.production){
			folder = "dev/"+folder;
		}

		folder = folder+this.usuario.data.user.id+"/";

		
		const AWSService = AWS;

		AWS.config.update({
			region: environment.awsS3.region,
			credentials: new AWSService.CognitoIdentityCredentials({
				IdentityPoolId: environment.awsS3.IdentityPoolId
			})
		});

		const s3 = new S3({
			apiVersion: '2006-03-01',
			params: { Bucket: environment.awsS3.bucketName}
		});


		return new Promise((resolve, reject) => {

			let ext = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
			let filename = Date.now().toString()+"."+ext;

			s3.upload({ 
				Key: folder+filename, 
				Bucket: environment.awsS3.bucketName, 
				Body: file, 
				ACL: 'public-read',
				StorageClass: 'INTELLIGENT_TIERING',
				Metadata: {
					'Cache-Control': "max-age=31557600"
				}
			}, function (err, data) {
				if(data){
					let result = {
						filename: filename,
						folder: folder,
						data: data
					}
					resolve(result);
				}
				if (err) {
					alert("Erro ao enviar arquivo");
					console.error(err);
				}
			});

		});

	}

	*/
	

}
