import { Component, OnInit, Input } from '@angular/core';
import { IForum } from "../../interfaces/Contents";

@Component({
  selector: 'forum-thread-card',
  templateUrl: './forum-thread-card.component.html',
  styleUrls: ['./forum-thread-card.component.scss'],
})
export class ForumThreadCardComponent implements OnInit {

	@Input() data:IForum;
	getLink = ()=> "/forum/"+this.data.id;

	constructor() { }

	ngOnInit() {}

}
