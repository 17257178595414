import { Component, OnInit, Input } from '@angular/core';
import { EventEmitterService } from '../../services/event-emitter.service';
import { IContent } from '../../interfaces/Contents';

@Component({
  selector: 'videoclass-card',
  templateUrl: './videoclass-card.component.html',
  styleUrls: ['./videoclass-card.component.scss'],
})
export class VideoclassCardComponent implements OnInit {

	@Input() data:IContent;

	getPercentage = ()=> this.data.user_view ? this.data.user_view.current_time/this.data.user_view.total_time*100 : 0;

	constructor() { }

	ngOnInit() {}

	OpenVideo(){
		EventEmitterService.get("openVideoModal").emit(this.data);
	}

}
