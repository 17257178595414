import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IContent, IJourneySteps, IQuizData, ISimulationData } from '../../interfaces/Contents';
import { AlertController } from '@ionic/angular';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'videoclass-item',
  templateUrl: './videoclass-item.component.html',
  styleUrls: ['./videoclass-item.component.scss'],
  providers: [DatePipe]
})
export class VideoclassItemComponent implements OnInit {

	icons:any = {
		content: "fas fa-play",
		simulation: "fas fa-procedures",
		quiz: "fas fa-check-square",
	}
	available_at:string;
	expires_at:string;
	
	@Input() data:IContent | IQuizData | ISimulationData;
	@Input() item:IJourneySteps;
	@Input() type:string = "content";
	@Input() showImage:boolean = true;
	@Input() queryParams:any;
	@Input() closed:boolean;

	getPercentage = ()=> this.getContent().user_view ? this.getContent().user_view.current_time/this.getContent().user_view.total_time*100 : 0;

	getContent = ():IContent => this.data as IContent;
	getQuiz = ():IQuizData => this.data as IQuizData;
	getSimulation = ():ISimulationData => this.data as ISimulationData;

	getIconStatus() {
		if(this.type=="content"){
			if(this.getContent().user_view) {
				return this.getContent().user_view.elapsed_time / this.getContent().user_view.total_time > 0.8 ? "success" : "warning"
			}
		} else if(this.type=="quiz"){
			if (this.getQuiz().user_responses.length > 0) {
				return this.getQuiz().user_responses.some((v) => v.correct_count / v.questions_count > 0.7) ? "success" : "warning"
			}
		} else if(this.type=="simulation"){
			if (this.getSimulation().user_play.length > 0) {
				return this.getSimulation().user_play.some((v) => v.finalized == "success" ) ? "success" : "warning"
			}
		}
	}

	constructor(
		private router:Router,
		public alertController: AlertController,
		private datePipe: DatePipe
	) { }

	ngOnInit() {
	}

	getLink():string {
		if(this.type=="content") {
			return "/video/"+this.getContent().slug;
		} else if(this.type=="quiz") {
			if ((this.data as IQuizData).questions_count > 0){
				return "/quiz/"+this.getQuiz().uniqid;
			}else{
				this.presentAlert("Aguarde administração liberar a prova!", "Prova em elaboração!");
			}
		} else if(this.type=="simulation") {
			return "/simulator";
		}
	}

	clickItem(){
		if(this.getLink())
			this.router.navigate([this.getLink()], {
				queryParams: this.queryParams,
				replaceUrl: true
			})
	}

	async presentAlert(msg:string, title?:string) {
		const alert = await this.alertController.create({
			header: title,
			message: msg,
			buttons: ['OK']
		});

		await alert.present();
	}

	verificarLiberacao(item: IJourneySteps): boolean {
		const dataAtual = new Date();
	
		if (item && item.available_at && item.expires_at) {
			this.available_at = this.datePipe.transform(this.item.available_at, 'dd/MM/yyyy');
			this.expires_at =  this.datePipe.transform(this.item.expires_at, 'dd/MM/yyyy');
			
			const availableAt = new Date(item.available_at);
			const expiresAt = new Date(item.expires_at);
	
			if (dataAtual < availableAt || dataAtual > expiresAt) {
				return true;
			}
		}
	
		return false;

	}

}
