import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {

	@Input() percentage:number = 50;
	@Input() showPercentage:boolean = true;

	getPercentage = ():number => Math.round(this.percentage);

	constructor() { }

	ngOnInit() {}

}
