import { environment } from "../../environments/environment";

interface PaymentStatus {
	label: string;
	style: string;
}

interface UserPlanConfig {
	status: Record<string, PaymentStatus>;
	payments_status: Record<string, PaymentStatus>;
	payments_types: Record<string, { label: string }>;
}

export const Config: { userPlan: UserPlanConfig } = {
	
	userPlan: {
		status: {
			trial: {
				label: "Em Teste",
				style: "info"
			},
			canceled: {
				label: "Cancelado",
				style: "fail"
			},
			active: {
				label: "Ativo",
				style: "success"
			},
			payment_error: {
				label: "Erro no pagamento",
				style: "fail"
			},
			created: {
				label: "Criado",
				style: "info"
			},
			pending: {
				label: "Pendente de pagamento",
				style: "info"
			}
		},
		payments_status: {
			ok: {
				label: "Pago",
				style: "success"
			},
			CAPTURED: {
				label: "Pago",
				style: "success"
			},
			DECLINED: {
				label: "Negado",
				style: "fail"
			},
			VOIDED: {
				label: "Estornado",
				style: "warning"
			},
			pending: {
				label: "Pendente",
				style: "warning"
			},
		},
		payments_types: {
			recurrence: {
				label: "Recorrência",
			},
			invoice: {
				label: "Boleto",
			},
			credit_card: {
				label: "Cartão de crédito",
			},
			once_credit_card: {
				label: "Cartão de crédito à vista",
			},
			installments_credit_card: {
				label: "Cartão de crédito parcelado",
			},
			pix: {
				label: "Pix",
			}
		}
	}
}
