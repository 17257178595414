import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'icon-header',
  templateUrl: './icon-header.component.html',
  styleUrls: ['./icon-header.component.scss'],
})
export class IconHeaderComponent implements OnInit {

	@Input() title:string;
	@Input() icon:string;
	@Input() link:string;
	@Input() iconStyle:string[] = [];

	constructor() { }

	ngOnInit() {}

}
