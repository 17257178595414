import { Component, OnInit, Input } from '@angular/core';
import { ApiRequestService } from '../../services/api-request.service';
import { IJourney } from "../../interfaces/Contents";
import { Router } from '@angular/router';

@Component({
  selector: 'journey-itens-menu',
  templateUrl: './journey-itens-menu.component.html',
  styleUrls: ['./journey-itens-menu.component.scss'],
})
export class JourneyItensMenuComponent implements OnInit {

	@Input() journeyId:number;
	@Input() currentId:number;
	@Input() type:string = "content";

	data:IJourney;

	constructor(
		private router:Router,
		private api:ApiRequestService,
	) { }

	ngOnInit() {
		this.loadJourney();
	}

	loadJourney(){
		this.api.get("journey/"+this.journeyId, true).subscribe((res:IJourney)=>{
			this.data = res;
		})
	}

	gotoNextContent() {

		let link:string;
		let findCurrent:boolean = false;

		this.data.titles.forEach(t=>{
			t.steps.forEach(s=>{

				if(s.type=="content") {
					if(findCurrent && !link) link = "/video/"+s.data.slug;
					if(!findCurrent && s.data.id==this.currentId) findCurrent = true;
				}
			})
		})

		if(link) {
			this.router.navigate([link], {
				queryParams: { disciplina_id: this.journeyId },
				replaceUrl: true
			})
		}
	}

}
