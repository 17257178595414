import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'card-button',
  templateUrl: './card-button.component.html',
  styleUrls: ['./card-button.component.scss'],
})
export class CardButtonComponent implements OnInit {

	@Input() title:string;
	@Input() icon:string;
	@Input() link:string;

	constructor() { }

	ngOnInit() {}

}
