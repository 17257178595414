import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'checkout-timeline',
  templateUrl: './checkout-timeline.component.html',
  styleUrls: ['./checkout-timeline.component.scss'],
})
export class CheckoutTimelineComponent implements OnInit {

	@Input() count:number = 4;
	@Input() current:number = 0;
	
	countArray = ():number[] => new Array(this.count);

	constructor() {}

	ngOnInit() {}



}
