import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from "../../interfaces/User";

@Component({
  selector: 'user-thumb',
  templateUrl: './user-thumb.component.html',
  styleUrls: ['./user-thumb.component.scss'],
})
export class UserThumbComponent implements OnInit {

	@Input() data:IUser;
	@Input() type:string = "user";

	constructor(
		public router:Router
	) { }

	ngOnInit() {}

	click() {
		if(this.type=="instructor") {
			this.router.navigate(["/instructor/"+this.data.id]);
		}
	}

}
