import { Component, OnInit } from '@angular/core';
import { EventEmitterService } from '../../services/event-emitter.service';
import { ApiRequestService } from '../../services/api-request.service';
import { IContent, IUserList } from '../../interfaces/Contents';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';

import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';


@Component({
	selector: 'app-content-preview-modal',
	templateUrl: './content-preview-modal.component.html',
	styleUrls: ['./content-preview-modal.component.scss'],
	animations: [

		trigger('videoModal', [
			state('visible', 
				style({
					display: 'block',
					opacity: 1,
				})
			),
			state('hidden', style({
					display: 'none',
					opacity: 0,
				})
			),
			transition('* => *', [
				style({ display: 'block' }),
				animate('250ms ease-out')
			])
		])

	]
})
export class ContentPreviewModalComponent implements OnInit {

	data:IContent = null;


	hideVideo = () => this.data = undefined;
	getLink = () => "/video/"+this.data.slug;
	getPercentage = ()=> this.data.user_view ? this.data.user_view.current_time/this.data.user_view.total_time*100 : 0;

	constructor(
		public navCtrl: NavController,
		private api: ApiRequestService
	) { }

	ngOnInit() {
		EventEmitterService.get("openVideoModal").subscribe((res)=>{
            this.data = res;
        });
	}

	gotoVideo(){
		this.navCtrl.navigateForward(this.getLink());
		this.hideVideo();
	}

	sendingUserList:boolean = false;
	toggleUserList(){

		this.sendingUserList = true;

		if(this.data.user_list) {
			this.api.delete("content/"+this.data.id+"/list", true).subscribe((res)=>{
				this.data.user_list = undefined;
				this.sendingUserList = false;
			})
		} else {
			this.api.post("content/"+this.data.id+"/list", {}, true).subscribe((res:IUserList)=>{
				this.data.user_list = res;
				this.sendingUserList = false;
			})
		}

	}

}
