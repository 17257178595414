import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements OnInit {

	@Input() data:any;

	constructor() { }

	ngOnInit() {}

}
