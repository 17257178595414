import { Component, OnInit, Input } from '@angular/core';
import { IInstructor } from "../../interfaces/User";

@Component({
  selector: 'instructor-card',
  templateUrl: './instructor-card.component.html',
  styleUrls: ['./instructor-card.component.scss'],
})
export class InstructorCardComponent implements OnInit {

	@Input() data:IInstructor;

	constructor() { }

	ngOnInit() {}

	getLink = ()=> "/instructor/"+this.data.id;

}
